import React, { useState } from 'react';
import Footer from './Footer';
import './App.css';

function App() {
  const [featureIndex, setfeatureIndex] = useState(0);
  
  const features = [
    {
      title: 'Personalized Learning Journey',
      description: 'Explore skill application in more various settings, from quick sessions to deep practices spanning over many sessions.',
      image: `${process.env.PUBLIC_URL}/user_learning_journey.png`
    },
    {
      title: 'Immersive Practice',
      description: 'Immerse yourself in multi-stage scenarios with several AI agents, actors and coaches, receiving in-depth feedback and guidance over an extended period.',
      image: `${process.env.PUBLIC_URL}/federated_fund_allocation.png`
    },
    {
      title: 'Agentic Architecture',
      description: 'Our multi-agent system creates immersive learning scenarios and reflective sessions, and provides timely, actionable feedback that enhances skill application.',
      image: `${process.env.PUBLIC_URL}/agentic_architecture.png`
    },
    {
      title: 'Role-play',
      description: 'Receive analytical feedback after role-playing with AI agents in various scenarios.',
      image: `${process.env.PUBLIC_URL}/new_product_launch.png`
    },
    {
      title: 'Voice-First Interactions',
      description: 'Designed for today’s AI-enabled workforce, Skillwork offers a hands-free, on-the-job learning experience that fits seamlessly into your workday.',
      image: `${process.env.PUBLIC_URL}/voice_interaction.png`
    },
    {
      title: 'One-on-One Sessions',
      description: 'Interact directly with AI coach in personalized sessions to refine your skills and receive instant feedback.',
      image: `${process.env.PUBLIC_URL}/one_on_one_session.png`
    }
  ];

  const handlePrevClick = (setter, length) => {
    setter((prevIndex) => (prevIndex - 1 + length) % length);
  };

  const handleNextClick = (setter, length) => {
    setter((prevIndex) => (prevIndex + 1) % length);
  };

  return (
    <div className="App">
      <header className="App-header">
        <nav className="navbar">
          <div className="logo">
            <img src={`${process.env.PUBLIC_URL}/logo192.png`} alt="Logo" className="logo-image" />
          </div>
          <ul className="nav-links">
            <li><a href="#about">About</a></li>
            <li><a href="#features">Features</a></li>
            <li><a href="https://app.reclaim.ai/m/tkornuta/coffee-chat">Contact</a></li>
          </ul>
        </nav>
      </header>

      <section className="hero">
        <div className="hero-content">
          <h1>Revolutionizing Soft Skills Development with AI and Behavioral Science</h1>
          <p>Improve your communication, negotiation, and leadership skills through real-time, contextual learning experiences.</p>
          <div className="cta-buttons">
            <a href="#features" className="cta-button">Learn More</a>
            <a className="cta-button" href="https://app.reclaim.ai/m/skillwork/demo-meeting">Request Demo</a>
          </div>
        </div>
      </section>

      <section id="about" className="about">
        <h2>About Us</h2>
        <p>Skillwork helps professionals grow their soft skills through real-time practice in work-related scenarios, driving measurable behavior change.</p>
        <div className="video-container">
          <iframe
            src="https://www.loom.com/embed/56c36fa963cf4f7e939140fb501ff1a8?sid=c80abd24-4faf-40e5-a063-5453398ac0db"
            frameBorder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowFullScreen
            title="Skillwork Intro Video"
          ></iframe>
        </div>
      </section>

      <section id="features" className="features">
        <h2>Features</h2>
        <div className="carousel">
        <div className="carousel-item">
          <div className="carousel-content">
            <div className="carousel-text">
              <h3>{features[featureIndex].title}</h3>
              <p>{features[featureIndex].description}</p>
            </div>
            <div className="carousel-image">
              <img src={features[featureIndex].image} alt={features[featureIndex].title} />
            </div>
            </div>
          </div>
          <div className="carousel-controls">
            <button onClick={() => handlePrevClick(setfeatureIndex, features.length)} className="carousel-control prev">&lt;</button>
            <button onClick={() => handleNextClick(setfeatureIndex, features.length)} className="carousel-control next">&gt;</button>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default App;
